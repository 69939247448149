import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import styled from "styled-components"

function SyaratBody({ props, propsLink }) {
  const {
    Header,
    Body1,
    Body2,
    Body3,
    Body4,
    Body5,
    Body6,
    Body7,
    Body8,
    Body9,
    Body10,
  } = props
  return (
    <>
      <p>
        {Header.Desc1}
        <SyaratLink to={propsLink.Service}>{Header.Link1}</SyaratLink>
        {Header.Desc2}
        <SyaratLink to={propsLink.Privacy}>{Header.Link2}</SyaratLink>
        {Header.Desc3}
        <SyaratLink to={propsLink.Service}>{Header.Link1}</SyaratLink>
        {Header.Desc4}
        <SyaratLink to={propsLink.Privacy}>{Header.Link2}</SyaratLink>
        {Header.Desc5}
      </p>
      {/* BODY1 */}
      <SyaratPoint>{Body1.Title1}</SyaratPoint>
      <ol
        type="1"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body1.Point1}</ContentLi>
        <ContentP>{Body1.Desc1}</ContentP>
        <ContentLi>{Body1.Point2}</ContentLi>
        <ContentP>{Body1.Desc2}</ContentP>
        <ContentLi>{Body1.Point3}</ContentLi>
        <ContentP>{Body1.Desc3}</ContentP>
        <ContentLi>{Body1.Point4}</ContentLi>
        <ContentP>{Body1.Desc4}</ContentP>
        <ContentP>{Body1.Desc4a}</ContentP>
        <ContentLi>{Body1.Point5}</ContentLi>
        <ContentP>{Body1.Desc5}</ContentP>
      </ol>
      {/* BODY2 */}
      <SyaratPoint>{Body2.Title1}</SyaratPoint>
      <ContentP>{Body2.Desc}</ContentP>
      <ol
        type="1"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body2.Point1}</ContentLi>
        <ContentP>{Body2.Desc1}</ContentP>
        <ContentP>
          {Body2.Desc1a}
          <SyaratLink to={propsLink.Affiliate}>{Body2.Desc1Link}</SyaratLink>
        </ContentP>
        <ContentLi>{Body2.Point2}</ContentLi>
        <ContentP>{Body2.Desc2}</ContentP>
        <ContentLi>{Body2.Point3}</ContentLi>
        <ContentP>{Body2.Desc3}</ContentP>
        <ContentLi>{Body2.Point4}</ContentLi>
        <ContentP>{Body2.Desc4}</ContentP>
        <ContentP>{Body2.Desc4a}</ContentP>
        <ContentLi>{Body2.Point5}</ContentLi>
        <ContentP>{Body2.Desc5}</ContentP>
        <ContentP>{Body2.Desc5a}</ContentP>
        <ContentP>{Body2.Desc5b}</ContentP>
        <ContentLi>{Body2.Point6}</ContentLi>
        <ContentP>{Body2.Desc6}</ContentP>
        <ContentLi>{Body2.Point7}</ContentLi>
        <ContentP>{Body2.Desc7}</ContentP>
        <ContentLi>{Body2.Point8}</ContentLi>
        <ContentP>{Body2.Desc8}</ContentP>
        <ContentLi>{Body2.Point9}</ContentLi>
        <ContentP>{Body2.Desc9}</ContentP>
        <ContentLi>{Body2.Point10}</ContentLi>
        <ContentP>{Body2.Desc10}</ContentP>
        <ContentLi>{Body2.Point11}</ContentLi>
        <ContentP>{Body2.Desc11}</ContentP>
        <ContentLi>{Body2.Point12}</ContentLi>
        <ContentP>{Body2.Desc12}</ContentP>
        <ContentP>{Body2.Desc12a}</ContentP>
        <ol
          type="a"
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>
            {Body2.Desc12b}
            <ul>
              <ContentLi>{Body2.Desc12c}</ContentLi>
              <ContentLi>{Body2.Desc12d}</ContentLi>
              <ContentLi>{Body2.Desc12e}</ContentLi>
              <ContentLi>{Body2.Desc12f}</ContentLi>
            </ul>
          </ContentLi>
          <ContentLi>
            {Body2.Desc12g}
            <ul>
              <ContentLi>{Body2.Desc12h}</ContentLi>
              <ContentLi>{Body2.Desc12i}</ContentLi>
              <ContentLi>{Body2.Desc12j}</ContentLi>
              <ContentLi>{Body2.Desc12k}</ContentLi>
            </ul>
          </ContentLi>
        </ol>
      </ol>
      {/* BODY3 */}
      <SyaratPoint>{Body3.Title1}</SyaratPoint>
      <ContentP>{Body3.Desc}</ContentP>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body3.Point1}</ContentLi>
        <ContentLi>{Body3.Point2}</ContentLi>
        <ContentLi>{Body3.Point3}</ContentLi>
        <ContentLi>{Body3.Point4}</ContentLi>
        <ContentLi>{Body3.Point5}</ContentLi>
        <ContentLi>{Body3.Point6}</ContentLi>
        <ContentLi>{Body3.Point7}</ContentLi>
        <ContentLi>{Body3.Point8}</ContentLi>
        <ContentLi>{Body3.Point9}</ContentLi>
        <ContentLi>{Body3.Point10}</ContentLi>
        <ContentLi>{Body3.Point11}</ContentLi>
      </ol>
      <ContentP>{Body3.Desc1}</ContentP>
      {/* BODY4 */}
      <SyaratPoint>{Body4.Title1}</SyaratPoint>
      <ol
        type="1"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body4.Point1}</ContentLi>
        <ContentLi>{Body4.Point2}</ContentLi>
      </ol>
      {/* BODY5 */}
      <SyaratPoint>{Body5.Title1}</SyaratPoint>
      <ContentP>{Body5.Desc1}</ContentP>
      {/* BODY6 */}
      <SyaratPoint>{Body6.Title1}</SyaratPoint>
      <ol
        type="1"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body6.Point1}</ContentLi>
        <ContentLi>
          {Body6.Point2}{" "}
          <SyaratLink to={propsLink.Service}>{Body6.Point2a}</SyaratLink>
          {Body6.Point2b}{" "}
          <SyaratLink to={propsLink.Privacy}>{Body6.Point2c}</SyaratLink>
          {Body6.Point2d}
        </ContentLi>
        <ContentLi>{Body6.Point3}</ContentLi>
        <ContentLi>{Body6.Point4}</ContentLi>
      </ol>
      {/* BODY7 */}
      <SyaratPoint>{Body7.Title1}</SyaratPoint>
      <ContentP>{Body7.Desc1}</ContentP>
      {/* BODY8 */}
      <SyaratPoint>{Body8.Title1}</SyaratPoint>
      <ol
        type="1"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body8.Point1}</ContentLi>
        <ContentLi>{Body8.Point2}</ContentLi>
        <ContentLi>{Body8.Point3}</ContentLi>
        <ContentLi>{Body8.Point4}</ContentLi>
      </ol>
      {/* BODY9 */}
      <SyaratPoint>{Body9.Title1}</SyaratPoint>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Body9.Point1}</ContentLi>
        <ContentLi>{Body9.Point2}</ContentLi>
      </ol>
      {/* BODY10 */}
      <SyaratPoint>{Body10.Title1}</SyaratPoint>
      <ContentP>
        {Body10.Desc1}
        <SyaratLink to={propsLink.ContactUs}>{Body10.Desc1Link}</SyaratLink>.
      </ContentP>
      <ContentP>{Body10.Desc2}</ContentP>
    </>
  )
}

export default SyaratBody

const SyaratSection = styled.section`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const SyaratContainer = styled(Container)`
  color: #6c6365;
  max-width: 800px;
  > h1 {
    font-family: "Typefez-reg";
    margin-top: 1.5rem;
    font-size: 36px;
    margin-bottom: 2rem;
    text-align: center;
  }
  > p {
    font-family: "Typefez-reg";
    text-align: justify;
  }
`

const SyaratLink = styled(Link)`
  color: #DB64A0;
`
const SyaratPoint = styled.h2`
  font-family: "Typefez-reg";
  color: #0F2C57;
`
export const ContentLi = styled.li`
  font-family: "Typefez-reg";
  text-align: justify;
  margin-bottom: 1rem;
`

export const ContentP = styled.p`
  font-family: "Typefez-reg";
  margin-bottom: 1rem;
  text-align: justify;
`
