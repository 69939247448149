import React from "react"
import '../components/layout.css'
import { useStateIfMounted } from "use-state-if-mounted"
import { NavigationBar } from "../components/NavigationBar/parent"
import { LayoutTextPage } from "../components/LayoutTextPage/parent"
import SyaratBody from "../components/SyaratReferral/SyaratBody"
import Footer from "../components/FooterComponents/Footer"
import {
  MainPageJson,
  PathJson,
  SyaratReferralJson,
} from "../components/location"
import { useLocation } from "@reach/router"
import Seo from "../components/SEOComponents/Seo"

function SyaratDanKetentuan() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const SyaratReferralJsonContent = SyaratReferralJson()
  const { Header } = SyaratReferralJsonContent || {}
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="referral-tnc" />
      <NavigationBar />
      <LayoutTextPage title={Header.Title1} secondTitle={Header.Title2}>
        <SyaratBody propsLink={PathContent} props={SyaratReferralJsonContent} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SyaratDanKetentuan
